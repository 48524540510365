module.exports = {
  selectLanguage: 'Selecione o seu idioma',
  connectFrom: 'Conéctate desde <strong>cualquier parte del mundo</strong>',
  partOfThis: 'Tú puedes ser parte de esto. ',
  weHave: 'Ver Concierto en Vivo:',
  donateNow: 'DONA POR VENEZUELA',
  date: '22 de Febrero 2019 ',
  hour: '10:00 Am',
  collectCaption:
    '<strong>Venezuela Aid Live</strong>, de la mano de <strong>Sir Richard Branson</strong>, han traído una gama de artistas de gran renombre internacional con el objetivo de recaudar <strong>10 millones</strong> de dólares, el cual será el punto de partida para recaudar <strong>100 millones para ayuda humanitaria y donaciones de gobiernos.</strong>',
  timeToAct: 'Voces Unidas por<br/> <strong><span>Venezuela</span></strong>',
  reasons:
    '<ul>' +
    '                    <li>Durante años los venezolanos sufrieron el deterioro de su calidad de vida, más de 250.000 venezolanos han sido asesinados en el país en los últimos 10 años, 33% de los niños que viven en situación de pobreza sufren de desnutrición y están en riesgo de presentar retardos en su desarrollo cognitivo y psicomotor. Miles de niños han muerto por falta de acceso a medicamentos y tratamientos médicos. Y más de 1.750.000 venezolanos se encuentran en Colombia en condición de migrantes.</li>\n' +
    '                    <li>El pasado 22 de febrero, los empresarios Richard Branson, Bruno y Fernán Ocampo. organizaron en Cúcuta el concierto VENEZUELA AID LIVE, a través del cual se lograron dos metas muy importantes.\n' +
    '                    <br/>Primero: Lograr sensibilizar y evidenciar ante el mundo, la grave emergencia humanitaria que actualmente vive Venezuela y las dimensiones de la crisis migratoria que esto ha generado en América Latina.' +
    '                    <br/>Segundo: Recaudar  fondos para alivianar algunas de las necesidades más urgentes en la zonas más afectadas.'+
    '                    </li>\n' +
    '                  </ul>',
  timeToActAction: 'Por eso y más, ¡ES HORA DE ACTUAR!',
  helpToShare: 'Ayudanos a compartir: ',
  peopleSay: 'La gente dice',
  peopleSayText:
    '“Sufro al ver a mi mamá quejándose de dolor porque no hay nada que se pueda hacer con su cáncer, las\n' +
    '                medicinas de la quimioterapia aquí no existen desde hace mucho, y no tengo los medios para\n' +
    '                conseguirlas por fuera, ya todos sabemos que no va a sobrevivi”',
  peopleSayFact:
    '<h3><span>50%</span> de los pacientes con <span>cáncer</span> en Venezuela no reciben tratamiento para\n' +
    '                    el <span>dolor</span></h3>',
  newsFronVzla: '<h2>Lo que pasa en <span>Venezuela</span></h2>',
  pressReleases: '<h2>Comunicados de <span>Prensa</span></h2>',
  pressCredentials: '<h2>Acreditación <span>Prensa</span></h2>',
  pressCredentialsActions:
    '<a rel="nofollow" href="https://goo.gl/forms/m8d0Bg7IzdugLjh42"><h4 style={{color:"#0449d0",textDecoration:"underline"}}>Acredítate aquí</h4></a>',
  liveStreaming: 'Ver Concierto en Vivo',
  sponsorByText: '<h2>Alianzas</h2>',
  mainBenefactorsText: '<h2>Main <span>Benefactors</span></h2>'

}
