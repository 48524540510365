import React from 'react'

const PressReleases = ({ releases }) => (
  <div>
    {
      releases.map((item, index) => {
        return (
          <div key={index} className="col-md-12 col-md-offset-1">
            <a rel="nofollow" target="_blank" href={item.url}><h4 style={{ color: "#0449d0", textDecoration: "underline" }}>{item.title}</h4></a>
          </div>
        )
      })
    }

  </div>
)

export default PressReleases
