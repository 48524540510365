import React from 'react'
import Layout from './index'
import { addLocaleData } from 'react-intl'

import messages from '../data/messages/es'
import pt from 'react-intl/locale-data/es'
import 'intl/locale-data/jsonp/es'

addLocaleData(pt)

export default props => <Layout {...props} i18nMessages={messages} />
