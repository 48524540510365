import React, { Component, PropTypes } from "react";

const q = {
  "en": [
    {
      "name": "How much money has been raised?",
      "response": `Until April 22 have been collected<br/>
      <b>USD: $2,351,812<b><br/><b>COP: $7.410.088.842<b>`
    },
    {
      "name": "How long are the donations?",
      "response": `April 22, the donations of the first phase are closed,
      but we will continue to raise funds through our
      website https://venezuelaaidlive.com and through a
      series of actions that we will present in the coming
      months.`
    },

    {
      "name": "How will manage the money raised?",
      "response": `The organizers of the Venezuela Aid Live have
      created Aid Live Foundation to guarantee the
      continuity of the efforts for the attention of the
      humanitarian emergency and to ensure the
      transparency in the use of the collected resources.`
    },
    {
      "name": "How will transparency in the use of resources be guaranteed?",
      "response": `All projects will be audited and the organizations
      that execute the programs must meet the criteria to
      receive funds from international funding agencies.`
    },
    {
      "name": "What will be done with the money raised?",
      "response": `Aid Live Foundation has been created as a second
      level organization that will support the foundations
      and organizations that are working in Venezuela
      and with the Venezuelan migrants in Colombia.<br/><br/>
      The areas of attention that we will focus on in this
      first phase will be:<br/><br/>
      <ul>
      <li>Comprehensive development for children.</li>
      <li>Nutritional accompaniment focused on
      childhood.</li>
      <li>Health and access to basic services for
      children.</li>
      <li>Promotion of well-being for Venezuelan
      migrant families in Colombia.</li>
      </ul>
      `
    },
    {
      "name": "What community do you think will help with these resources?",
      "response": `A percentage of the resources will be allocated to
      assist Venezuelan migrants in Colombia, especially
      in regions with a high rate of migrant population.
      The remaining percentage will be invested in
      Venezuela through programs of national scope that
      can benefit the most vulnerable populations.`
    },
    {
      "name": "Will another Venezuela Aid Live be made?",
      "response": `We have many plans and great news for the coming
      months, but for now our goal is to start investing
      resources in social programs and start generating
      welfare for Venezuelans. And in that we will be
      concentrated the next months.`
    },
    {
      "name": "Who is in charge of Aid Live?",
      "response": `Aid Live is an organization formed by a group of
      social development specialists with more than 10
      years of experience working with communities in
      Venezuela and Colombia, who will be responsible
      for ensuring the proper use of resources and the
      impact of projects.`
    }
  ],
  "es": [
    {
      "name": "¿Cuánto dinero se ha recaudado?",
      "response": `Hasta el 22 de abril se han recaudado:<br>
        <b>USD: $2,351,812<b><br/><b>COP: $7.410.088.842<b>`
    },
    {
      "name": "¿Hasta cuándo van las donaciones?",
      "response": `El 22 de abril se cierran las donaciones de la
        primera fase, pero continuaremos recaudando
        fondos a través de nuestra página web
        https://venezuelaaidlive.com y a través de una serie
        de acciones que daremos a conocer en los
        próximos meses.`
    },
    {
      "name": "¿Quién va a manejar el dinero recaudado?",
      "response": `Los organizadores del Venezuela Aid Live han
      creado Aid Live Foundation para garantizar la
      continuidad de los esfuerzos para la atención de la
      emergencia humanitaria y para velar por la
      transparencia en el uso de los recursos
      recaudados.`
    },
    {
      "name": "¿Cómo se va a garantizar la transparencia en el uso de los recursos?",
      "response": `Todos los proyectos serán auditados y las
      organizaciones que ejecutarán los programas
      deben cumplir con los criterios para recibir fondos
      de agencias internacionales de financiamiento.`
    },
    {
      "name": "¿Qué se va a hacer con el dinero recaudado?",
      "response": `Aid Live Foundation se ha creado como una
      organización de segundo nivel que apoyará a las
      fundaciones y organizaciones que se encuentran
      trabajando en Venezuela y con los migrantes
      venezolanos en Colombia.<br/><br/>
      Las áreas de atención en las que nos enfocaremos
      en esta primera fase, serán:<br/><br/>
      <ul>
      <li>Desarrollo integral para la infancia.</li>
      <li>Acompañamiento nutricional enfocado en la
      niñez.</li>
      <li>Salud y acceso a servicios básicos para la
      infancia.</li>
      <li>Promoción del bienestar para las familias
      venezolanas migrantes en Colombia.</li>
      </ul>
      `
    },
    {
      "name": "¿A qué comunidad se piensa apoyar con estos recursos?",
      "response": `Un porcentaje de los recursos serán destinados a
      atender migrantes venezolanos en Colombia,
      especialmente en regiones con alto índice de
      población migrante.<br/>
      El porcentaje restante se invertirá en Venezuela a
      través de programas de alcance nacional con los
      que se puedan beneficiar a las poblaciones más
      vulnerables.`
    },
    {
      "name": "¿Se hará otro Venezuela Aid Live?",
      "response": `Tenemos muchos planes y grandes noticias para
      los próximos meses, pero por lo pronto nuestro
      objetivo es comenzar a invertir los recursos en
      programas sociales y comenzar a generar bienestar
      para los venezolanos. Y en eso estaremos
      concentrados los próximos meses.`
    },
    {
      "name": "¿Quién está a cargo de Aid Live?",
      "response": `Aid Live es una organización conformada por un
      grupo de especialistas en desarrollo social con más
      de 10 años de experiencia trabajando con
      comunidades en Venezuela y Colombia, quienes
      serán los responsables de velar por el buen uso de
      los recursos y por el impacto de los proyectos.`
    }
  ]
};


class QA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 0
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }


  render() {
    const { view } = this.state;
    const { langKey } = this.props;

    return (
      <section className="questions">
        <div className="container">
          <div className="col-md-12">
            <h1 className="t1 text-center title">Q&A</h1>
            {
              q[langKey].map(({ name, response }, index) => {
                return (
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-primary">
                      <div className="panel-heading" role="tab" id="headingOne">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion"
                            onClick={() => this.setState({ view: index })}
                            aria-expanded="true" aria-controls="collapseOne">
                            {name}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" style={{ display: view === index ? "" : "none" }}
                        className="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingOne">
                        <div className="panel-body">
                          <div dangerouslySetInnerHTML={{ __html: response }} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }

          </div>
        </div>
      </section>
    );
  }
}

export default QA;
