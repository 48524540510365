import React, { Component, PropTypes } from 'react'
import axios from 'axios'
class ArtistComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: 1,
      artistList: [],
      currentArtist: [],
      artist: [],
    }
  }

  chunk(arr, chunkSize) {
    var R = []
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
      R.push(arr.slice(i, i + chunkSize))
    return R
  }

  componentDidMount() {
    const self = this
    axios
      .get('/artists.json')
      .then(function({ data }) {
        self.setState({
          artistList: self.chunk(data.artist, 7),
          currentArtist: self.chunk(data.artist, 7)[0],
          artist: data.artist,
        })
      })
      .catch(function(error) {
        // handle error
        console.log(error)
      })
      .then(function() {
        // always executed
      })
  }
  slider(step) {
    let { slider } = this.state
    if (step === 0) {
      let number = slider + 1 >= this.state.artistList.length ? 0 : slider + 1
      this.setState({ slider: number })
    } else {
      let number = slider - 1 <= 0 ? 0 : slider - 1
      this.setState({ slider: number })
    }
    this.setState({ currentArtist: this.state.artistList[slider] })
  }
  render() {
    const { title, strong } = this.props
    return (
      <div>
        <h3 className="title-artists">
          {title} <br />
          <strong>{strong}</strong>
        </h3>
        <div className="col-md-12 col-xs-12 artists">
          {this.state.currentArtist.map((item, index) => {
            return (
              <div key={index} className="col-md-12  col-xs-12 card-artist">
                <div className="col-md-4 col-xs-4">
                  <img src={item.photo} alt={item.name} />
                </div>
                <div className="col-md-8 col-xs-8">
                  <h4>
                    {item.name}
                    <br />
                    <strong>{item.lastname}</strong>
                  </h4>
                  {/*<span> <img src={item.flag}/>&nbsp;{item.country}</span>*/}
                </div>
              </div>
            )
          })}
        </div>
        <div className="col-md-12 col-xs-12 text-center div-arrow">
          <i
            className="fas fa-arrow-circle-left fa-3x"
            onClick={() => {
              this.slider(1)
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <i
            className="fas fa-arrow-circle-right fa-3x"
            onClick={() => {
              this.slider(0)
            }}
          />
        </div>
      </div>
    )
  }
}

export default ArtistComponent
