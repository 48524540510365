import React, {useState}from 'react'
import Layout from '../layouts/es'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import PressInfo from '../pressReleases'
import Count from '../components/CountDown'
import PressReleases from '../components/PressRelases'
import Questions from "../components/QA"
// import Team from "../components/Team"
import Artist from '../components/Invited'

import { Twitter, Facebook } from 'react-social-sharing'


const IndexPage = (props) => {
  return (
    <Layout location={props.location}>
      <section className="countdown">
        <div className="container">
          <div className="col-md-12" style={{paddingBottom:"10%"}}>
            <div className="col-md-7">
              <div
                className="bs-example"
                data-example-id="responsive-embed-16by9-iframe-youtube"
              >
                <div className="embed-responsive embed-responsive-16by9">
                <iframe width="1280" height="720" src="https://www.youtube.com/embed/7zgMFcuPFHY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className="text-video">
                  Creemos que cada uno de nosotros tiene el poder de cambiar el mundo. Nuestra manera de hacerlo es por medio del arte y la música.
                </p>
                <br />
                <div className="text-center">
                  <button id="gvng-donate3" className={"js-footer-donate-btn "}>
                    <FormattedMessage id="donateNow" />
                  </button>
                </div>

                <br />
              </div>
            </div>
            <div className="col-md-5">
              <h1 className="t1">Venezuela está Sufriendo</h1>
              <br />
              <ul className="status">
                <li>Durante años los venezolanos sufrieron el deterioro de su calidad de vida, más de 250.000 venezolanos han sido asesinados en 
                  el país en los últimos 10 años, 33% de los niños que viven en situación de pobreza sufren de desnutrición y están en riesgo de presentar 
                  retardos en su desarrollo cognitivo y psicomotor. Miles de niños han muerto por falta de acceso a medicamentos y tratamientos médicos. 
                  Y más de 1.750.000 venezolanos se encuentran en Colombia en condición de migrantes.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="moment">
        <div className="col-md-6" />
        <div className="container">
          <div className="col-md-12">
            <div className="col-md-8" style={{ paddingRight: 0 }}>
              <img src="/img/girl.jpg" alt="Es el momento de actuar" />
              <h3 className="title">
                <FormattedHTMLMessage id="timeToAct" />
              </h3>
              <hr />
              <FormattedHTMLMessage id="reasons" />
              <div className="col-md-12 text-center">
                <h3 className="subtitle">
                  <FormattedMessage id="timeToActAction" />
                </h3>
                <br />
                <br />
                <button id="gvng-donate3">
                  <FormattedMessage id="donateNow" />
                </button>
              </div>
              <div className="col-md-12 social">
                <div className="col-md-6 text-right">
                  <h3>
                    <FormattedMessage id="helpToShare" />
                  </h3>
                </div>
                <div className="col-md-3 col-xs-6 text-right">
                  <Twitter
                    solid
                    medium
                    message="Tú, puedes ser parte de esta reconstrucción así que solo tenemos pocas horas para recaudar 100 millones de dólares."
                    link="https://ayudaylibertad.com/"
                  />
                </div>
                <div className="col-md-3 col-xs-6 text-left">
                  <Facebook solid medium link="https://ayudaylibertad.com/" />
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ paddingLeft: 0 }}>
              <Artist title={'Artistas'} strong={'Invitados'} />
            </div>
          </div>
        </div>
      </section>

       <section className="videos" style={{ paddingBottom: 35 }}>
        <div className="container">
          <div className="col-md-12">
            <FormattedHTMLMessage id="pressReleases" />
            <br />
          </div>
          <PressReleases releases={PressInfo['es']} />
        </div>
      </section>

      <Questions langKey={'es'}/>

      <section className="people">
        <div className="container">
          <div className="col-md-12">
            <h2>
              <FormattedMessage id="peopleSay" />
            </h2>
          </div>
          <div className="col-md-12 card-people">
            <div className="col-md-3 text-center">
              <img src="/img/testimonio.png" alt="Testimonio" />
            </div>
            <div className="col-md-9 text-center">
              <p>
                <FormattedMessage id="peopleSayText" />
              </p>
              <hr />
              <FormattedHTMLMessage id="peopleSayFact" />
            </div>
          </div>
        </div>
      </section>

      <section className="videos">
        <div className="container">
          <div className="col-md-12">
            <FormattedHTMLMessage id="newsFronVzla" />
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <div className="col-md-4">
              <div
                className="bs-example"
                data-example-id="responsive-embed-16by9-iframe-youtube"
              >
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/xGbpIL_fv7o"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <h4>
                Hambre en Venezuela: el drama de la severa desnutrición infantil
              </h4>
            </div>
            <div className="col-md-4">
              <div
                className="bs-example"
                data-example-id="responsive-embed-16by9-iframe-youtube"
              >
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/8o3ECvxWTE8"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <h4>Especial: Venezuela, éxodo y crisis humanitaria</h4>
            </div>
            <div className="col-md-4">
              <div
                className="bs-example"
                data-example-id="responsive-embed-16by9-iframe-youtube"
              >
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/i-XA5ZzExWY"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <h4>Los caminantes: la travesía de los venezolanos</h4>
            </div>
          </div>
        </div>
      </section>
      
      {/*<section className="videos" style={{ paddingBottom: 35 }}>
        <div className="container sponsorBy-block">
          <FormattedHTMLMessage id="mainBenefactorsText" />


          <div className="partners-list mb-5">

            <div className="partner">
              <img
                alt="Betcris"
                title="Betcris"
                src="/img/partners/betcrises.png"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
            <div className="partner">
              <img
                alt="WeSend"
                title="WeSend"
                src="/img/partners/wesend.svg"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="videos" style={{ paddingBottom: 35 }}>
        <div className="container sponsorBy-block">
          <FormattedHTMLMessage id="sponsorByText" />


          <div className="partners-list mb-5">
            <div className="partner">
              <img
                alt="puntored"
                title="puntored"
                src="/img/partners/puntologo.png"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
            <div className="partner">
              <img
                alt="cruz verde"
                title="cruz verde"
                src="/img/partners/cruzverde.svg"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
            <div className="partner">
              <img
                alt="Fundación Solidaridad por Colombia"
                title="Fundación Solidaridad por Colombia"
                src="/img/partners/fundacion-solidaridad-por-colombia-logo.svg"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
            <div className="partner">
              <img
                alt="GVNG"
                title="GVNG"
                src="/img/partners/GVNG-logo.svg"
                className="partners-list__image mr-3 ml-1 mr-md-5 ml-md-2"
              />
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  )
}
export default IndexPage
